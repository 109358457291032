
import { Component, Vue } from "vue-property-decorator";
import EnTableLayout from '../../../../ui-components/TableLayout/src/main.vue';
import * as API_refund from '@/api/refund';
import XDialog from "@/components/x-dialog/x-dialog.vue";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { handleDownload } from '@/utils';
import { Foundation } from '@/../ui-utils';

@Component({
  name: "refundList",
  components: {
    EnTableLayout,
    XDialog
  }
})

export default class refundList extends Vue {
  loading = false;
  importLoading = false;
  pageData: any = {};
  advancedForm: any = {};
  shop_type = this.$store.getters.shopInfo.shop_type;//商城类型
  params: any = {
    page_no: 1,
    page_size: 20,
    mall_type: this.$store.getters.shopInfo.mall_type
  };
  keyWord: string = 'sn';
  keyWord_Value: string = ''
  //视频播放弹窗
  videoUrl: any = '';
  videoding: boolean = false;
  /** 审核 */
  refundAudit = $xDialog.create({
    title: '审核',
    width: '30vw',
    beforeConfirm: () => this.handlerAuditAdopt()
  });
  refundInfo: any = {};
  auditForm: any = {
    refund_type: '1',
    reason: ''
  };
  refund_img = [];
  refundMoney = 0;

  /** 退款失败原因 */
  refundFailReason = $xDialog.create({
    title: '退款失败原因',
    width: '20vw',
    disableConfirm: true,
    disableCancel: true
  });
  refund_fail_reason = '';

  mounted() {
    console.log(this.$store.getters.shopInfo);

    this.GET_RefundOrder();
  }

  /** 分页大小发生改变 */
  handlePageSizeChange(size) {
    this.params.page_size = size;
    this.GET_RefundOrder();
  }

  /** 分页页数发生改变 */
  handlePageCurrentChange(page) {
    this.params.page_no = page;
    this.GET_RefundOrder();
  }
  /**视频播放弹窗 */
  playVideo(src) {
    this.videoUrl = src;
    this.videoding = true;
  }
  /** 高级搜索事件触发 */
  advancedSearchEvent() {
    const params = {};
    delete this.params[this.keyWord]
    if (this.keyWord_Value) {
      params[this.keyWord] = this.keyWord_Value;
    }

    this.params = {
      ...params,
      ...this.params
    }

    this.GET_RefundOrder();
  }


  /** 获取退款单列表数据 */
  GET_RefundOrder() {
    this.loading = true;
    const params = {
      ...this.advancedForm,
      ...this.params
    }
    API_refund.getAIRefundList(params).then(resp => {
      this.loading = false;
      this.pageData = {
        data: resp.data,
        page_no: resp.page_no,
        page_size: resp.page_size,
        data_total: resp.data_total,
      };
    })
  }

  /** 查看详情 */
  handleRefundDetail(row) {
    this.$router.push({
      path: `/combo-card-shop/order/refund-detail/${row.sn}`
    })
  }

  /** 审核 */
  handleOpenAuditDialog(row) {
    API_refund.getRefundDetails(row.sn).then((response) => {
      this.refundAudit.display();
      this.refundInfo = response.refund
      this.refundMoney = response.refund.refund_shop_price
      if (this.refundInfo.refund_img) {
        this.refund_img = this.refundInfo.refund_img.split(',')
      }
    })
  }

  handlerAuditAdopt() {
    const _params = {
      agree: this.auditForm.refund_type,
      reason: this.auditForm.refund_reason,
      refund_shop_price: this.refundMoney,
      sn: this.refundInfo.sn,
    }
    API_refund.refundAIAuth(this.refundInfo.sn, _params).then(() => {
      this.refundAudit.dismiss();
      this.GET_RefundOrder();
    });
    return false;
  }

  /** 退款失败原因 */
  showRefundFailReason(row) {
    this.refundFailReason.display();
    this.refund_fail_reason = row.refund_fail_reason;
  }

  /** 导出维权订单 */
  submitImport() {
    this.importLoading = true;
    let orderFunc = 'getRefundList';
    let filterVals = [
      'goods_name',
      'sn',
      'trade_sn',
      'mobile',
      'create_time',
      'refuse_type_text',
      'refund_status_text',
    ];
    let tHeaders = [
      '商品名称',
      '售后单号',
      '订单编号',
      '用户账号',
      '申请时间',
      '类型',
      '状态',
    ];
    if (this.$store.getters.shopInfo.shop_type === 3) {
      orderFunc = 'enterpriseRefundsList';
    }
    if (this.$store.getters.shopInfo.shop_type === 2) {
      orderFunc = 'getSellerRefundList';
      let filterVals = [
        'goods_name',
        'sn',
        'trade_sn',
        'mobile',
        'create_time',
        'refuse_type_text',
        'refund_status_text',
      ];
      let tHeaders = [
        '商品名称',
        '售后单号',
        '订单编号',
        '用户账号',
        '申请时间',
        '类型',
        '状态',
      ];
    }
    let params = { ...this.params };
    params.page_no = 1;
    params.page_size = 99999;
    params.self_type = this.advancedForm.self_type
    API_refund.getAIRefundList(params).then((response) => {
      response.data.map((item, index) => {
        item.create_time = Foundation.unixToDate(
          item.create_time,
          'yyyy-MM-dd hh:mm:ss'
        );
      });
      handleDownload(response.data, tHeaders, filterVals, '退款订单');
      this.importLoading = false;
    })
      .catch((res) => {
        this.importLoading = false;
      });
  }
}

